.tableContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin: 20px 0px;
    margin-top: 10;
  }
  
  .gradTable {
    width: 85%; /* Adjust based on your design preference */
    border-collapse: collapse;
    margin: 10px 2px;
    border: 2px solid black;
    min-height: 150px;
  }
  
  .gradTable th, .gradTable td {
    border: 2px solid black;
    padding: 8px;
    text-align: left;
    vertical-align: top; /* Align content to the top */
  }
  
  .gradTable th {
    background-color: grey; /* Header background */
    color: white;
    min-height: 60px;
  }
  
  .gradTable td {
    font-size: 14px;
  }

