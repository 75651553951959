.researchers-container {
  font-family: Arial, sans-serif; /* or any preferred font */
  padding-top: 20px;
  margin: auto;
  text-align: left;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.researcher-info {
  width: 100%; /* Ensures that the block takes the full width of the container */
  margin-bottom: 20px; /* Space between entries */
}

.researcher-info p {
  margin: 1px 0; /* Small margin for paragraph separation */
}

h1 {
  color: red; /* Styling header similar to the image */
  font-size: 8mm;
  font-weight: bolder;
  text-decoration: underline;
  margin: 0; /* Ensures that the header has no extra margin that could misalign it */
}

.award {
  color: blue; /* Highlighting awards */
}