  .tableContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
  }
  
  .mediaTable {
    width: 40%; /* Adjust based on your design preference */
    border-collapse: collapse;
    margin: 10px 2px;
    border: 0px solid black;
  }

  .mediaTableMobile {
    width: 90%; /* Adjust based on your design preference */
    border-collapse: collapse;
    margin: 0 auto; /* Centers the table horizontally */
    border: 0px solid black;
}

  .mediaTableMobile th {
    background-color: white; /* Header background */
    color: crimson;
    min-height: 20px;
    min-width: 360px;
    font-size: 24px;
    text-align: center;
  }

  .mediaTableMobile td {
    text-align: center; /* Centers text and inline-elements like images and links horizontally */
    vertical-align: middle; /* Centers content vertically within the cell */
    justify-content: center;
    align-items: center;
  }

  .mediaTableMobile td img {
    width: auto;  
    height: auto; 
    max-width: 100%;  
}
  
  .mediaTable th, .mediaTable td {
    border: 0px solid black;
    padding: 8px;
    text-align: center;
    vertical-align: top; /* Align content to the top */
  }
  
  .mediaTable th {
    background-color: white; /* Header background */
    color: crimson;
    min-height: 20px;
    min-width: 360px;
    font-size: 24px;
    text-align: center;
  }
  
  .customTable td {
    font-size: 18px;
    margin: 1em;
    text-align: center;
  }


  
  